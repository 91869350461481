import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using react-router for navigation
import './LandingPage.css';

const Footer = () => {
    return (
      <footer style={{ backgroundColor: '#F5F5F1', padding: '1rem', textAlign: 'center', borderTop: '0.5px solid #31625C', paddingLeft: '10px', paddingRight: '10px' }}>
        <div> 
          <p style={{ color: '#31625C' }}>
            &copy; {new Date().getFullYear()} Parapluie Technologies Limited. All rights reserved.
          </p>
          <nav className="footer" style={{ marginTop: '0.5rem', display: 'flex', justifyContent: 'center' }}>
            <ul style={{ listStyle: 'none', padding: 0, display: 'flex', justifyContent: 'center' }}>
              <li style={{ marginRight: '1rem' }}>
                <Link to="/terms-of-service" style={{ color: '#31625C', textDecoration: 'none' }}>
                  Terms of Service
                </Link>
              </li>
              <li>
                <Link to="/privacy-policy" style={{ color: '#31625C', textDecoration: 'none' }}>
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </nav>
        <p style={{ color: '#31625C' }}>info@originsecurity.tech</p>
        </div>
      </footer>
    );
  };
export default Footer;